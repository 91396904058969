import Harshita from "./images/harshita_test.jpg";
import Bhargavi from "./images/bhargavi_test.jpg";
import Varssha from "./images/varssha_test.jpg";
import Janhavi from "./images/janhavi_test.jpg";
import Akshita from "./images/akshita_test.jpg";
import Abhijit from "./images/abhijit_test.jpg";
import Suneel from "./images/suneel_test.jpg";
import Utkarsh from "./images/utkarsh_test.jpg";
import Abhirup from "./images/abhirup_test.jpg";
import Rishav from './images/rishav_test.jpg'
import Anudeep from "./images/anudeep_test.jpg";
import Aastha from "./images/aastha_test.jpg";
import Vidushi from "./images/vidushi_test.jpg";
import Khushi from "./images/khushi_test.jpg";
import Syed from "./images/syed_test.jpg";
import Shreya from "./images/shreya_test.jpg";
import Akshay from "./images/akshay_test.jpg";
import Pooja from "./images/pooja_test.jpg";

const testimonials= {
  Harshita,
  Bhargavi,
  Varssha,
  Janhavi,
  Akshita,
  Abhijit,
  Suneel,
  Utkarsh,
  Abhirup,
  Anudeep,
  Rishav,
  Aastha,
  Vidushi,
  Khushi,Syed,Shreya,Akshay,Pooja
};

export default testimonials;
